import React from 'react'
import '../App.css'
import {Link} from "react-router-dom"
function Header() {
    return (
        <>
      
  
        <nav class="navbar navbar-expand-sm navbar-light w-100 bg-success justify-content-between">
          <h3 className="text-white ms-4">  Question Bank</h3>
            <ul class="navbar-nav ">
              <Link to="/question-bank/addquestion">
                <button class="nav-item btn bg-white ps-4 pe-4 me-3 card-style">
                    Add <i className="bi bi-plus"></i>
                </button>
                </Link>
                <li class="nav-item">
                  <img src="https://icons.veryicon.com/png/o/internet--web/prejudice/user-128.png" height="40px" className="rounded-pill me-4 bg-white" width="40px" />
                </li>
            </ul>
        </nav>
        </>
    )
}

export default Header