import React from "react";

function Dashboard() {
  return (
    <div className="container-fluid">
      <div className="row bg-success">
        <div className="col-3 me-auto text-white">
          <h2>Question Bank</h2>
        </div>
        <div className="col-2 ms-auto p-2">
          <button className="btn btn-danger p-1">
            Add <i className="bi bi-plus-lg"></i>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
