// import React from 'react'
import React from "react";
import { useState } from "react";
import image from "../images/rajat.png";
import axios from "axios";
import { useMatch } from "react-router-dom";
import { useEffect } from "react";

function Editquestion() {
  const match = useMatch("/question-bank/edit/:id");
  let list = [
    "Gk",
    "english",
    "general-science",
    "history",
    "quantitative-aptitude",
    "verbal-reasoning",
    "nonverbal-reasoning",
    "physics",
    "chemistry",
    "biology",
    "politics",
    "accountancy",
    "economics",
    "geography",
    "alzebra",
    "trignomatry",
    "calculus",
    "geomatry",
    "hindi-grammer",
    "computer",
  ];
  const [category, setCategory] = useState("");
  const [data, setData] = useState("");
  const [img, setImage] = useState("");
  const [statement1, setStatement1] = useState("");
  const [option, setOption] = useState([]);
  const [answer, setAnswer] = useState("");
  const GetcreateQuestion = async () => {
    let res = await axios.get(`${url}api/v1/questions/${match.params.id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    setData(res.data.level);
    setOption(res.data.options);
    setStatement1(res.data.statement);
    console.log(res.data);
    setCategory(res.data.category_id);
    // setStatement1(res.data)
    setAnswer(res.data.answer);
  };
  let row = new Map();
  const ChooseOption = (value, checked) => {
    if (checked) {
      row.set(value, checked);
    } else {
      row.delete(value);
    }
  };
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    console.log(name)
    console.log(value)
    console.log(option, "Previous VAlue")
    if(name === "option1"){
        option[0] = value;
    } else  if(name === "option2"){
        option[1] = value;
    }else  if(name === "option3"){
        option[2] = value;
    }else  if(name === "option4"){
        option[3] = value;
    }
    setOption([
      ... option
    ]);
    console.log(option, "Next VAlue")
  };

  let url = "http://api.modcons.net/";
  const handleSubmit = async () => {
    let array = [];
    row.forEach((item) => {
      array.push(item);
    });
    console.log(array);
    let data1 = {
      statement: statement1,
      answer: answer,
      options: option,
      category_id: category,
      level: data,
    };
    let res = await axios.put(
      `${url}api/v1/questions/${match.params.id}`,
      data1,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    console.log(res);
  };


  const UploadImage = () => {
    let formdata = new FormData();
    formdata.append("file", image);

    //   axios.post(`url`,formdata, { headers: {
    //     'Content-Type': 'multipart/form-data; '
    //   }})
  };


  useEffect(() => {
    GetcreateQuestion();
  }, []);


  return (
    <>
      <div className="container">
        <div className="row mt-5">
          <div className="col-6">
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <div className="text-start">
                <label htmlFor="" className="text-start mb-2">
                  Statement
                </label>
                <input
                  type="text"
                  value={statement1}
                  className="form-control"
                  onChange={(e) => setStatement1(e.target.value)}
                  placeholder="Enter Statement"
                />
              </div>
              <div className="row mt-3 text-start">
                <label htmlFor="" className="text-start mb-2 ms-5">
                  Option 1
                </label>
                <div className="col-1">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      ChooseOption(option[0], e.target.checked);
                    }}
                     
                  />
                </div>
                <div className="col-9">
                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Enter Option"
                    name="option1"
                    onChange={handleChange}
                    value={option[0]}
                  />
                </div>
              </div>
              <div className="row text-start">
                <label htmlFor="" className="text-start mb-2 ms-5">
                  Option 2
                </label>
                <div className="col-1">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      ChooseOption(option[1], e.target.checked);
                    }}
                  />
                </div>
                <div className="col-9">
                  <input
                    type="text"
                    className="form-control mb-3"
                    name="option2"
                    value={option[1]}
                    onChange={handleChange}
                    placeholder="Enter Option"
                  />
                </div>
              </div>
              <div className="row text-start">
                <label htmlFor="" className="text-start mb-2 ms-5">
                  Option 3
                </label>
                <div className="col-1">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      ChooseOption(option[2], e.target.checked);
                    }}
                  />
                </div>
                <div className="col-9">
                  <input
                    type="text"
                    className="form-control mb-3"
                    name="option3"
                    value={option[2]}
                    onChange={handleChange}
                    placeholder="Enter Option"
                  />
                </div>
              </div>
              <div className="row text-start">
                <label htmlFor="" className="text-start mb-2 ms-5">
                  Option 4
                </label>
                <div className="col-1">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      ChooseOption(option[3], e.target.checked);
                    }}
                  />
                </div>
                <div className="col-9">
                  <input
                    type="text"
                    className="form-control mb-3"
                    name="option4"
                    value={option[3]}
                    onChange={handleChange}
                    placeholder="Enter Option"
                  />
                </div>
              </div>
              <div className="row text-start">
                <label htmlFor="" className="text-start mb-2 ms-5">
                  Choose level
                </label>
                <div className="col-1"></div>
                <div className="col-9">
                  <select
                    className="form-select"
                    value={data}
                    onChange={(e) => {
                      setData(e.target.value);
                    }}
                    aria-label="Default select example"
                  >
                    {/* <option selected  >Choose level</option> */}
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4"> 4</option>
                    <option value="5">5</option>
                  </select>
                </div>
              </div>
              <div className="row text-start">
                <label htmlFor="" className="text-start mb-2 ms-5">
                  Choose level
                </label>
                <div className="col-1"></div>
                <div className="col-9">
                  <select
                    class="form-select"
                    value={category}
                    onChange={(e) => {
                      setCategory(e.target.value);
                    }}
                    aria-label="Default select example"
                  >
                    {/* <option selected  >Choose Category</option> */}
                    {list.map((res) => (
                      <option value={res}>{res}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-3">
                  <input
                    type="button"
                    value="Update"
                    onClick={handleSubmit}
                    className="btn btn-success shadow-none"
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="col-6 mt-5 position-relative">
            <i className="bi bi-plus position-absolute"></i>
            {/* <div>
                        <img src={image?URL.createObjectURL(image):''} alt="Upload image" className="border border-2" height="180px" width="200px" />
                        <input type="file" style={{display:"none"}} onChange={(e) => e.target.files[0]} accept="image/*" id="img"/>
                    </div> */}

            <button
              className="btn btn-success mt-2"
              onClick={() => document.getElementById("img").click()}
            >
              Upload
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Editquestion;
