import React from "react";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import img from "../images/our.gif";
import "../App.css";
import{ toast }from "react-toastify"

function Login() {
  const url="http://api.modcons.net/"
  const [show, setShow] = useState(true);
  let navigate = useNavigate();
  const [login, setLogin] = useState({
    email: "",
    password: "",
  });
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setLogin({
      ...login,
      [name]: value,
    });
  };
  setTimeout(() => {
    setShow(false);
  }, 3000);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = {
      username: login.email,
      password: login.password,
      role: "ques_bank_admin",
    };
    try {
      
      let res = await axios.post(
        `${url}api/v1/auth/login`,
        data,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      console.log(res, "response");
      localStorage.setItem("token", res.data.access_token);
      let token=localStorage.getItem("token")
      if(res.data.status == true){
        toast.success("login successfull") 
        navigate("/question-bank/question/1");
      }else{
        navigate("/")
        toast.error("wrong username or password")
      }
     
    } catch {
      console.log("error");
      toast.error("error")
    }
  };
  return (
    <div className="section fit1">
      {show ? (
        <img src={img} className="" alt="" width="100%" height="100%" />
      ) : localStorage.getItem("token") ?(navigate("/question-bank/question/1")):(
       

          <div className="container mt-5">
            <form onSubmit={handleSubmit}>
                <div className="col-md-4 text-start">
                  <h4 className="mb-3">LOG IN</h4>
                  <label className="text-start">Username</label>
                  <br></br>
                  <input
                    className="form-control"
                    type="email"
                    name="email"
                    value={login.email}
                    onChange={handleChange}
                    placeholder="Username"
                  />
                  <br></br>
                  <label className="text-start">Password</label>
                  <br></br>
                  <input
                    className="form-control"
                    type="password"
                    name="password"
                    value={login.password}
                    onChange={handleChange}
                    placeholder="Password"
                  />
                  <br></br>
                  <input
                    type="submit"
                    value="Login"
                    className="btn btn-success"
                  />
                </div>
              </form>
          </div>

          
      )}
    </div>
  );
}

export default Login;
