import React from "react";
import { useState } from "react";
import image from "../images/rajat.png";
import axios from "axios";

const QuestionBank = () => {
  let list = [
    "Gk",
    "english",
    "general-science",
    "history",
    "quantitative-aptitude",
    "verbal-reasoning",
    "nonverbal-reasoning",
    "physics",
    "chemistry",
    "biology",
    "politics",
    "accountancy",
    "economics",
    "geography",
    "alzebra",
    "trignomatry",
    "calculus",
    "geomatry",
    "hindi-grammer",
    "computer",
  ];
  const [category, setCategory] = useState("");
  const [data, setData] = useState("");
  const [img, setImage] = useState("");
  const [statement, setStatement] = useState("");
  const [option, setOption] = useState({
    option1: "",
    option2: "",
    option3: "",
    option4: "",
  });
  const [answer, setAnswer] = useState("");
  const url = "http://api.modcons.net/";

  let row = new Map();
  const ChooseOption = (value, checked) => {
    if (checked) {
      row.set(value, value);
    } else {
      row.delete(value);
    }
  };
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setOption({
      ...option,
      [name]: value,
    });
  };

  const addQuestion = async () => {
    let array = [];
    row.forEach((item) => {
      array.push(item);
    });
    console.log(array);
    let data1 = {
      statement: statement,
      answer: array[0],
      options: [option.option1, option.option2, option.option3, option.option4],
      category_id: category,
      level: data,
    };
    try {
      let res = await axios.post(`${url}api/v1/questions`, data1, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      window.history.back(1);
      return res;
    } catch (e) {
      console.log(e);
    }
  };


  const addAndActivateQuestion = async () => {
    try {
      var res = await addQuestion();
      if (res.data.id) {
        await axios.post(`${url}api/v1/questions/${res.data.id}/activate`, "", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
      }
      window.history.back(1);
    } catch {}
  };


  const UploadImage = () => {
    let formdata = new FormData();
    formdata.append("file", image);

    //   axios.post(`url`,formdata, { headers: {
    //     'Content-Type': 'multipart/form-data; '
    //   }})
  };
  return (
    <>
      <div className="container">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            addQuestion();
          }}
        >
          <div className="row mt-5">
            <div className="col-6">
              <div className="text-start">
                <label htmlFor="" className="text-start mb-2">
                  Statement
                </label>
                <input
                  type="text"
                  value={statement}
                  className="form-control"
                  onChange={(e) => setStatement(e.target.value)}
                  placeholder="Enter Statement"
                />
              </div>
              <div className="row mt-3 text-start">
                <label htmlFor="" className="text-start mb-2 ms-5">
                  Option 1
                </label>
                <div className="col-1">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      ChooseOption(option.option1, e.target.checked);
                    }}
                  />
                </div>
                <div className="col-9">
                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Enter Option"
                    name="option1"
                    value={option.option1}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="row text-start">
                <label htmlFor="" className="text-start mb-2 ms-5">
                  Option 2
                </label>
                <div className="col-1">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      ChooseOption(option.option2, e.target.checked);
                    }}
                  />
                </div>
                <div className="col-9">
                  <input
                    type="text"
                    className="form-control mb-3"
                    name="option2"
                    value={option.option2}
                    onChange={handleChange}
                    placeholder="Enter Option"
                  />
                </div>
              </div>
              <div className="row text-start">
                <label htmlFor="" className="text-start mb-2 ms-5">
                  Option 3
                </label>
                <div className="col-1">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      ChooseOption(option.option3, e.target.checked);
                    }}
                  />
                </div>
                <div className="col-9">
                  <input
                    type="text"
                    className="form-control mb-3"
                    name="option3"
                    value={option.option3}
                    onChange={handleChange}
                    placeholder="Enter Option"
                  />
                </div>
              </div>
              <div className="row text-start">
                <label htmlFor="" className="text-start mb-2 ms-5">
                  Option 4
                </label>
                <div className="col-1">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      ChooseOption(option.option4, e.target.checked);
                    }}
                  />
                </div>
                <div className="col-9">
                  <input
                    type="text"
                    className="form-control mb-3"
                    name="option4"
                    value={option.option4}
                    onChange={handleChange}
                    placeholder="Enter Option"
                  />
                </div>
              </div>
              <div className="row text-start">
                <label htmlFor="" className="text-start mb-2">
                  {" "}
                  Level
                </label>

                <div className="col-9">
                  <select
                    className="form-select"
                    value={data}
                    onChange={(e) => {
                      setData(e.target.value);
                    }}
                    aria-label="Default select example"
                  >
                    <option selected>Choose Level</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4"> 4</option>
                    <option value="5">5</option>
                  </select>
                </div>
              </div>
              <div className="row text-start">
                <label htmlFor="" className="text-start mb-2  mt-3">
                  {" "}
                  Category
                </label>

                <div className="col-9">
                  <select
                    class="form-select w-100"
                    value={category}
                    onChange={(e) => {
                      setCategory(e.target.value);
                    }}
                    aria-label="Default select example"
                  >
                    <option selected>Choose Category</option>
                    {list.map((res) => (
                      <option value={res}>{res}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-6 mt-5 position-relative">
              {/* <i className="bi bi-plus position-absolute"></i> */}
              {/* <div>
                            <img src={image?URL.createObjectURL(image):''} alt="Upload image" className="border border-2" height="180px" width="200px" />
                            <input type="file" style={{display:"none"}} onChange={(e) => e.target.files[0]} accept="image/*" id="img"/>
                        </div> */}

              {/* <button className="btn btn-success mt-2" onClick={() => document.getElementById("img").click()} >Upload</button> */}
            </div>
            <div className="row mt-5 ">
              <div className="col-1 ms-auto">
                <input
                  type="submit"
                  value="Save"
                  className="btn btn-success shadow-none"
                />
              </div>
              <div className="col-2 ">
                <button
                  onClick={addAndActivateQuestion}
                  className="btn btn-success shadow-none"
                  value={true}
                >
                  Save & Active
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default QuestionBank;
