import logo from "./logo.svg";
import "./App.css";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import QuestionBank from "./components/QuestionBank";
import DashboardActive from "./components/DashboardActive";
import QuestionStage2 from "./components/QuestionStage2";
// import Login1 from './components/Login1';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Editquestion from "./components/editquestion";
import axios from "axios";
import Layout from "./components/layout";
// import {ToastContainer} from "toastify"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Detail from "./components/detail";
import React, { Component }  from 'react';

function App() {
  return (
    <BrowserRouter>
      <div className="App box">
        <Routes>
          <Route path="/question-bank" element={<Login />} />
          <Route
            path="*"
            element={
              <Layout
                data={
                  <Routes>
                    <Route
                      path="question-bank/addquestion"
                      element={<QuestionBank />}
                    />
                    <Route
                      path="question-bank/edit/:id"
                      element={<Editquestion />}
                    />
                    <Route
                      path="question-bank/detail/:id"
                      element={<Detail />}
                    />
                    <Route
                      path="question-bank/question/:page"
                      element={<DashboardActive />}
                    />
                  </Routes>
                }
              />
            }
          ></Route>
        </Routes>

        <ToastContainer />
      </div>
    </BrowserRouter>
  );
}

export default App;
