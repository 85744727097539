import React from 'react'
import Header from "./header"
import Footer from "./footer"
import './../App.css'
function Layout({data}) {
  return (
    <div className="box">
        <Header/>
        <div class="content">
      {data}
      </div>
      <Footer/>
    </div>
  )
}

export default Layout
