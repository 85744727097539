import React,{useState,useEffect} from 'react'
import "../App.css";
import {Link,useMatch} from "react-router-dom"
import axios from "axios"

function Detail() {
    let match=useMatch("question-bank/detail/:id")
    const[data,setData]=useState([])
    let url = "http://api.modcons.net/";
      const Edit = async () => {
        let res = await axios.put(
          `${url}api/v1/questions/${match.params.id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        GetcreateQuestion();
      };
      const GetcreateQuestion = async () => {
        let res = await axios.get(
          `${url}api/v1/questions/${match.params.id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setData(res.data);
        // setOption(res.data);
        // setStatement(res.data)
        console.log(res.data);
        // setTotalpage(res.data.length);
      };
      const deleteQuestion = (id) => {
        const del = axios.delete(`${url}api/v1/questions/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        console.log(id);
        GetcreateQuestion();
      };
      useEffect(() => {
        GetcreateQuestion();
        // GetactiveQuestion()
      }, []);
  return (
    <div className="container">
       <div className="col-12 mt-4 ">
              <div className="card mt-3  text-start p-4">
                <div className="row">
                    <div className="col-2 me-auto mt-2"><h3>Question</h3></div>
                    
                    {data.status == "question_created" ? (
                    <div className="col-3 ms-auto"><button
                          className="btn btn-danger ms-3 me-3 mt-3"
                          onClick={() => deleteQuestion(data.id)}
                        >
                          Delete
                        </button>
                        <Link to={`/question-bank/edit/${data.id}`}>
                          <button className="btn btn-primary ms-4 mt-3">
                            Edit
                          </button>
                        </Link>
                        </div>
                    ):""}

                    
                </div>
                  <div className="mb-1">
                    <b>Statement: </b>
                    <span> {data.statement}</span>
                  </div>
                  {/* <div>
                  data?.options?.map((item)=>{
                    return   <div className="mb-2">
                    <b>Option   </b>
                    <span> {item}</span>
                    </div>
                  })
                </div> */}
                
                  <div>
                  <b>Answer: </b>
                  <span>{ data.answer}</span>
                  </div>
                  <div className="mb-1">
                    <b>Level: </b>
                    <span> {data.level}</span>
                  </div>
                  <div className="mb-1">
                    <b>Category: </b>
                    <span> {data.category_id}</span>
                  </div>

              </div>
       
      </div>
    </div>
  )
}

export default Detail
