import React from 'react'
import "../App.css"

function Footer() {
  return (
    <div>
     <nav className="navbar navbar-expand-sm bg-success navbar-dark mt-5 align-self-end">
  <div className="container-fluid">
    <a className="navbar-brand ms-5" href="#">Footer</a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="collapsibleNavbar">
      <ul className="navbar-nav">
     
      </ul>
    </div>
  </div>
</nav>  
    </div>
  )
}

export default Footer;
