import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useMatch, useNavigate, useLocation } from "react-router-dom";
import Pagination from "./pagination";

function DashboardActive() {
  const match = useMatch("/question-bank/question/:page");
  let location = useLocation();
  let navigate = useNavigate();
  const limit = 10;
  const [data, setData] = useState([]);
  let list = [
    "gk",
    "english",
    "general-science",
    "history",
    "quantitative-aptitude",
    "verbal-reasoning",
    "nonverbal-reasoning",
    "physics",
    "chemistry",
    "biology",
    "politics",
    "accountancy",
    "economics",
    "geography",
    "alzebra",
    "trignomatry",
    "calculus",
    "geomatry",
    "hindi-grammer",
    "computer",
  ];
  const [category, setCategory] = useState("");
  const [data1, setData1] = useState("");
  const [img, setImage] = useState("");
  const [totalpage, setTotalpage] = useState(0);
  const [statement, setStatement] = useState("");
  // const[page,setPage]=useState(1)
  const [option, setOption] = useState({
    option1: "",
    option2: "",
    option3: "",
    option4: "",
  });
  const [answer, setAnswer] = useState("");

  let row = new Map();
  const ChooseOption = (value, checked) => {
    if (checked) {
      row.set(value, value);
    } else {
      row.delete(value);
    }
  };
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setOption({
      ...option,
      [name]: value,
    });
  };

  let url = "http://api.modcons.net/";
  const handleSubmit = async () => {
    let array = [];
    row.forEach((item) => {
      array.push(item);
    });
    console.log(array);
    let data2 = {
      statement: statement,
      answer: array[0],
      options: [option.option1, option.option2, option.option3, option.option4],
      category_id: category,
      level: data1,
    };
  };
  let newparam = new URLSearchParams(location.search);
  const onPaginationChange = (newval) => {
    navigate(`/question-bank/question/${newval}`);
  };
  const GetcreateQuestion = async () => {
    let res = await axios.get(
      `${url}api/v1/questions?page=${Number(match.params.page)}&size=${Number(
        limit
      )}&status=question_created`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    setData(res.data.data.questions);
    setOption(res.data.data);
    // setStatement(res.data)

    setTotalpage(res.data.data.count);
  };

  const GetactiveQuestion = async () => {
    let res = await axios.get(
      `${url}api/v1/questions?page=${Number(match.params.page)}&size=${Number(
        limit
      )}&status=question_active`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    setData(res.data.data.questions);
    setTotalpage(res.data.data.count);
  };
  useEffect(() => {
    GetcreateQuestion();
    // GetactiveQuestion()
  }, [match.params.page]);

  // const Edit = async () => {
  //   let res = await axios.get(
  //     `${url}api/v1/questions?page=1&size=100&status=question_active`,
  //     {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       },
  //     }
  //   );
  // };

  const Active = async (id) => {
    let res = await axios.post(
      `${url}api/v1/questions/${id}/activate`,"",
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  };

  const deleteQuestion = (id) => {
    const del = axios.delete(`${url}api/v1/questions/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    GetcreateQuestion();
  };

  const liveQuestion = () => {
    document.getElementById("live").style.backgroundColor = "#198754";
    document.getElementById("create").style.backgroundColor = "white";
    document.getElementById("create").style.color = "black";
    document.getElementById("live").style.color = "white";
    GetactiveQuestion();
  };

  const createQuestion = () => {
    document.getElementById("live").style.backgroundColor = "white";
    document.getElementById("create").style.backgroundColor = "#198754";
    document.getElementById("create").style.color = "white";
    document.getElementById("live").style.color = "black";

    GetcreateQuestion();
  };

  return (
    <div className="container">
      <div className="row mt-5">
        <div
          className="col-6 btn w-50"
          style={{ backgroundColor: "#198754", color: "white" }}
          id="create"
          onClick={createQuestion}
        >
          <b>CREATED</b>
        </div>
        <div className="col-6 btn w-50" id="live" onClick={liveQuestion}>
          <b>LIVE</b>
        </div>
      </div>

      <div className="col-12 mt-4 ">
        {data.map((item) => {
          return (
            <Link to={`/question-bank/detail/${item.id}`} className="text-dark link-style">
           
         
              <div className="card mt-3 card-style text-start p-4">
                <div className="mb-1">
                  <b>Statement: </b>
                  <span>{item.statement}</span>
                </div>
                <div>
                  <b>Answer: </b>
                  <span>{item.answer}</span>
                </div>
                <div className="text-end">
                  {item.status == "question_created" ? (
                    <div>
                      <button
                        className="btn btn-success mt-3"
                        onClick={() => Active(item.id)}
                      >
                        Activate
                      </button>
                      
                      {/* <button
                        className="btn btn-danger ms-3 mt-3"
                        onClick={() => deleteQuestion(item.id)}
                      >
                        Delete
                      </button> */}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              </Link>
          );
        })}
      </div>
      <Pagination
        count={totalpage}
        data={data}
        page={Number(match.params.page)}
        limit={Number(limit)}
        onPageChange={(value) => {
          console.log(value, "value=================");
          onPaginationChange(value);
        }}
      />
    </div>
    // </div>
  );
}

export default DashboardActive;
